import Repository from '../Repository'

const resource = '/u/reports'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    fetchById(id){
        return Repository.get(`${resource}/${id}`) 
    },
    fetchAlert(){
        return Repository.get('/u/alerts')
    }
}