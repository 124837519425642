import Repository from './Repository'

const resource = '/notifications'
export default {
  fetch(){
    return Repository.get(`${resource}`) 
  },
  read(id){
    return Repository.get(`${resource}/${id}`) 
  }
}