import Repository from '../Repository'

const resource = '/u/domains'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    save(params){
        return Repository.post(`${resource}`,{data: params}) 
    },
    fetchGroups(){
        return Repository.get(`${resource}/groups`) 
    },
    saveGroups(data){
        return Repository.post(`${resource}/groups`,data) 
    },
    delGroup(id){
        console.log(id)
        return Repository.delete(`${resource}/groups/${id}`) 
    }
    
}