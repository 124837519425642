import Repository from '../Repository'

const resource = '/a/bank'
export default {
    fetch(){
        return Repository.get(`${resource}/fetch-bank`) 
    },
    editBank(params){
        return Repository.post(`${resource}/add-bank`,params)
    },
    editPaypal(params){
        return Repository.post(`${resource}/edit-paypal`,params)
    }
    
}