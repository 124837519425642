import axios from 'axios'

const baseDomain = 'https://api-socks.fastproxy.vip'
//const baseDomain = 'http://127.0.0.1:3000'
const baseUrl = `${baseDomain}/api`


let token = localStorage.getItem('token') || ''


export default axios.create({
    baseURL: baseUrl,
    timeout: 250000,
    headers: { Authorization: token }
})

//326vibsG772c