import Repository from '../Repository'

const resource = '/u/info'
export default {
    fetch(){
        return Repository.get(`${resource}/fetch`) 
    },
    updateInfo(params){
        return Repository.put(`${resource}/update-info`,params)
    },
    changePassword(params){
        return Repository.put(`${resource}/change-password`,params)
    },
    changeApiKey(){
        return Repository.get(`${resource}/change-apikey`)
    },
    changeKeyPort(){
        return Repository.get(`${resource}/change-keyport`)
    },
    changeProxyPass(){
        return Repository.get(`${resource}/change-ppass`)
    },

    getSettings(){
        return Repository.get(`${resource}/settings`,params)
    },
    getBW(){
        return Repository.get(`${resource}/bandwidth`)
    }
   
}