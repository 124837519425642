import Repository from '../Repository'

const resource = '/a/dashboard'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    fetchReports(params){
        return Repository.get(`${resource}/reports`)
    },
    editPaypal(params){
        return Repository.post(`${resource}/edit-paypal`,params)
    },
    saveSettings(data){
        return Repository.post(`${resource}/settings`,data)
    },
    getLogs(){
        return Repository.get(`${resource}/logs`)
    },
    getPlan(){
        return Repository.get(`${resource}/plan-report`)
    },

    checkPlan(){
        return Repository.get(`${resource}/plan-checking`)
    }
    
}