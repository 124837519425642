import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import VueGtag from "vue-gtag";


import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'


import Repository from './api/Repository'

import { VueReCaptcha } from 'vue-recaptcha-v3'
// import VueAnalytics from 'vue-analytics';

import VueFbCustomerChat from 'vue-fb-customer-chat'

let token = localStorage.getItem('token') || null
if(token){
  Repository.defaults.headers.common['Authorization'] = token
  store.commit('auth/auth_success',{token: token})
}


Repository.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
  if(router.currentRoute.name != "login"){
    localStorage.removeItem('token')
    location.href="login"
  }
  
  //place your reentry code
 }
 return error;
});

//siytekey 6LdJswMnAAAAAI-Tocd4ET60NiSFAmHYyknX96wu
//secret key: 6LdJswMnAAAAAKjSoH-J1z6XgcTpsxU5ZOn3mqKv

Vue.use(VueReCaptcha, { siteKey: '6LdJswMnAAAAAI-Tocd4ET60NiSFAmHYyknX96wu' })

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Vue.use(VueAnalytics, {
//     id: 'G-E8RVRLZ82D',
//     router
// })

Vue.use(VueGtag, {
  config: { id: "G-CXQ1PYBD8M" }
})

Vue.use(VueFbCustomerChat, {
  page_id: '109951255486399', //  change 'null' to your Facebook Page ID,
  theme_color: '#333333', // theme color in HEX
  locale: 'vi_VN', // default 'en_US'
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

