import Repository from '../Repository'

const resource = '/u/nplans'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    fetchAll(){
        return Repository.get(`${resource}/all`) 
    },
    buyBW(data){
        return Repository.post(`${resource}`,data)
    }
}