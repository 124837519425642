import Repository from '../Repository'

const resource = '/a/reports'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    fetchById(id){
        return Repository.get(`${resource}/${id}`) 
    },
}