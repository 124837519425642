import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  //base: '/apps',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path:'/',
      beforeEnter: (to, from, next) => {
        console.log(to)
        if(store.getters.isLoggedIn){
          let data = jwtdecode(localStorage.getItem("token"))
          if(data.role == 0)
            next('/a/panel')
          else
            next('/panel')
        }else{
          next('/login')
        }
        return
      }
    },
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@/views/Index.vue'),
    //   meta: {
    //     requiresAuth: false,
    //     resource: 'Auth',
    //     layout: 'full',
    //   },
    // },
    {
      path: '/panel',
      name: 'home',
      component: () => import('@/views/users/dashboard/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('@/views/users/billing/Billing.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/users/Order.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Order',
        breadcrumb: [
          {
            text: 'Order',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-funds',
      name: 'add-funds',
      component: () => import('@/views/users/AddFunds.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Api',
        breadcrumb: [
          {
            text: 'Api',
            active: true,
          },
        ],
      }, 
    },
    {
      path: '/list-proxies',
      name: 'list-proxies',
      component: () => import('@/views/users/list-proxy/ListProxy.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Api',
        breadcrumb: [
          {
            text: 'Api',
            active: true,
          },
        ],
      }, 
    },
    {
      path: '/list-ports',
      name: 'list-ports',
      component: () => import('@/views/users/list-ports/ListPorts.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Api',
        breadcrumb: [
          {
            text: 'Api',
            active: true,
          },
        ],
      }, 
    },
    // {
    //   path: '/log-ports',
    //   name: 'log-ports',
    //   component: () => import('@/views/users/list-proxy/PortLog.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     role: 1,
    //     pageTitle: 'Api',
    //     breadcrumb: [
    //       {
    //         text: 'Api',
    //         active: true,
    //       },
    //     ],
    //   }, 
    // },
    {
      path: '/api-documents',
      name: 'api-documents',
      component: () => import('@/views/users/Api.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Api',
        breadcrumb: [
          {
            text: 'Api',
            active: true,
          },
        ],
      }, 
    },
    {
      path: '/term',
      name: 'term',
      component: () => import('@/views/users/Term.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Tearm',
        breadcrumb: [
          {
            text: 'Tearm',
            active: true,
          },
        ],
      }, 
    },
    // {
    //   path: '/support',
    //   name: 'support',
    //   component: () => import('@/views/users/Support.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     role: 1,
    //     pageTitle: 'Support',
    //     breadcrumb: [
    //       {
    //         text: 'Support',
    //         active: true,
    //       },
    //     ],
    //   }, 
    // },
    {
      path: '/support/helpdesk/:id',
      name: 'support-chat',
      component: () => import('@/views/users/SupportChat.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Support',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
        ],
      }, 
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('@/views/users/AccountSettings.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Api',
        breadcrumb: [
          {
            text: 'Api',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: () => import('@/views/users/billing/Pricing.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Api',
        breadcrumb: [
          {
            text: 'Api',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/pass-regexp',
    //   name: 'bypass-domain',
    //   component: () => import('@/views/users/list-proxy/BypassGroup.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     role: 1,
    //     pageTitle: 'Api',
    //     breadcrumb: [
    //       {
    //         text: 'Api',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/guide-documents',
      name: 'guide-documents',
      component: () => import('@/views/users/Guide.vue'),
      meta: {
        requiresAuth: true,
        role: 1,
        pageTitle: 'Api',
        breadcrumb: [
          {
            text: 'Api',
            active: true,
          },
        ],
      },
    },
    {
      path: '/a/panel',
      name: 'apanel',
      component: () => import('@/views/admin/dashboard/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        role: 0,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/a/accounts-manager',
      name: 'accounts-manager',
      component: () => import('@/views/admin/AccountsManager.vue'),
      meta: {
        requiresAuth: true,
        role: 0,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
      
    },
    {
      path: '/a/list-proxy',
      name: 'list-proxy',
      component: () => import('@/views/admin/ListProxy.vue'),
      meta: {
        requiresAuth: true,
        role: 0,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
      
    },
    {
      path: '/a/analytics',
      name: 'analytics',
      component: () => import('@/views/admin/dashboard/Analytics.vue'),
      meta: {
        requiresAuth: true,
        role: 0,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
      
    },
    {
      path: '/a/users-manager',
      name: 'users-manager',
      component: () => import('@/views/admin/user-manager/UsersManager.vue'),
      meta: {
        requiresAuth: true,
        role: 0,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/a/users-manager/:id',
      name: 'users-edit',
      component: () => import('@/views/admin/user-manager/ViewUser.vue'),
      meta: {
        requiresAuth: true,
        role: 0,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/a/settings',
      name: 'settings',
      component: () => import('@/views/admin/Settings.vue'),
      meta: {
        requiresAuth: true,
        role: 0,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/a/support/helpdesk/:id',
      name: 'support-chat-a',
      component: () => import('@/views/admin/user-manager/SupportChat.vue'),
      meta: {
        requiresAuth: true,
        role: 0,
        pageTitle: 'Support',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
        ],
      }, 
    },
    {
      path: '/m/manager',
      name: 'member-manager',
      component: () => import('@/views/member/list-proxy/Manager.vue'),
      meta: {
        requiresAuth: true,
        role: 2,
        pageTitle: 'Support',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
        ],
      }, 
    },
    {
      path: '/m/api',
      name: 'member-api',
      component: () => import('@/views/member/Api.vue'),
      meta: {
        requiresAuth: true,
        role: 2,
        pageTitle: 'Support',
        breadcrumb: [
          {
            text: 'Support',
            active: true,
          },
        ],
      }, 
    },
    {
      path: '/m/term',
      name: 'term-m',
      component: () => import('@/views/users/Term.vue'),
      meta: {
        requiresAuth: true,
        role: 2,
        pageTitle: 'Tearm',
        breadcrumb: [
          {
            text: 'Tearm',
            active: true,
          },
        ],
      }, 
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        requiresAuth: false,
        resource: 'Auth',
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        requiresAuth: false,
        resource: 'Auth',
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/reset-password/:confirmCode',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: (to, from, next) => {
        store.dispatch('auth/logout')
      },
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/403',
      name: '403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: '404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})


router.beforeEach(async (to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      if(!store.getters['auth/userInfo']){
        await store.dispatch('auth/get_uinfo')
      }
      const r = to.matched.find(record => record.meta);
      if(r && r.meta.role == store.getters['auth/userInfo'].role){
      //if(to.matched.some(record => record.meta.role && record.meta.role == store.getters.userInfo.role)) {
        next()
        return
      }else{
        next('/403')
        return
      }
    }
    next('/login') 
  } else {
    if(to.name == "login" || to.name == "register"){
      if (store.getters['auth/isLoggedIn']){
        if(!store.getters['auth/userInfo']){
          await store.dispatch('auth/get_uinfo')
        }
        if(store.getters['auth/userInfo'].role == 0)
          next('/a/panel')
        else
          next('/panel')
      }else{
        next(0)
      }
    }else{
      next() 
    }
    
  }
})

export default router
