import Repository from '../Repository'

const resource = '/a/users'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    fetchById(id){
        return Repository.get(`${resource}/${id}`) 
    },
    fetchPlanById(id){
        return Repository.get(`${resource}/plans/${id}`) 
    },
    fetchPortsById(id){
        return Repository.get(`${resource}/ports/${id}`) 
    },
    fetchPayment(id){
        return Repository.get(`${resource}/payments/${id}`) 
    },
    statusZone(id,params){
        return Repository.put(`${resource}/zones/${id}`,params) 
    },
    updatePlan(params){
        return Repository.put(`${resource}/plans/update`,params) 
    },
    handlePayment(id,params){
        return Repository.put(`${resource}/payments/${id}`,params) 
    },
    clonePort(id,params){
        return Repository.post(`${resource}/ports/clone/${id}`,params) 
    },
    addUser(data){
        //console.log(data)
        return Repository.post(`${resource}`,data) 
    },
    addMoreBW(id,bw){
        return Repository.post(`${resource}/bw/${id}`,bw)
    },
    editUser(id,data){
        //console.log(data)
        return Repository.put(`${resource}/${id}`,data) 
    },
    activeUser(id,data){
        //console.log(data)
        return Repository.put(`${resource}/active/${id}`) 
    },
    blockUser(id){
        return Repository.put(`${resource}/block-user/${id}`)
    },
    deleteUsers(id){
        return Repository.delete(`${resource}/${id}`)
    },
    addfund(id,amount){
        return Repository.put(`${resource}/add-funds/${id}`,amount)
    },
    getSubs(id){
        return Repository.get(`a/subscriptions/${id}`)
    },
    fetchBilling(id){
        return  Repository.get(`${resource}/billings/${id}`)
    },
    fetchPorts(id){
        return  Repository.get(`${resource}/ports/${id}`)
    },
    deleteUnValidPort(ids){
        return  Repository.post(`${resource}/ports/delete`,{ids: ids})
    },
    deletePort(id){
        return  Repository.delete(`${resource}/ports/${id}`)
    },
    deleteAllPort(uid,ids){
        return  Repository.post(`${resource}/ports/delete-all/${uid}`,{ids: ids})
    }
    
}