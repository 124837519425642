import Repository from '../Repository'

const resource = '/u/plans'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    requestPlans(){
        return Repository.post(`${resource}`)
    },
    checkPlans(){
        return Repository.get(`${resource}/order`)
    },
    buyBW(qty){
        return Repository.post(`${resource}/buy-bandwidth`,{
            tb: qty
        })
    },
    buyAccount(sltype){
        return Repository.post(`${resource}/buy-account`,{
            sltype: sltype
        })
    },
    buyAccountMem(membership){
        return Repository.post(`${resource}/buy-custom-account/${membership}`)
    },
    getConfig(id){
        return Repository.get(`${resource}/config?id=${id}`)
    },
    getCustomAccount(mem){
        return Repository.get(`${resource}/custom/${mem}`)
    },
    saveConfig(id,params){
        let p = Object.assign({},params)
        p.ip = p.ip.split('\n').filter(x=>x != "")
        return Repository.put(`${resource}/config/${id}`,p)
    },
    orderPorts(number,days){
        return Repository.post(`${resource}/order-ports`,{number: number, days: days})
    },
    setAuto(id){
        return Repository.post(`${resource}/set-renew/${id}`)
    },
    change(arr){
        return Repository.post(`${resource}/changes`,{items: arr})
    },
    changeIP(arr){
        return Repository.post(`${resource}/changes/ip`,{items: arr})
    },
    renew(id){
        return Repository.post(`${resource}/renew/${id}`)
    },
    reactive(id){
        return Repository.get(`${resource}/re-active/${id}`)
    }
}