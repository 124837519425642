import Repository from '../Repository'

const resource = '/m/proxies'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    editPort(data){
        return Repository.put(`${resource}`,{data: data})
    },
    changeGeo(data){
        return Repository.put(`${resource}/geo`,{data: data})
    },
    changeRotate(data){
        return Repository.put(`${resource}/rotate`,{data: data})
    },

    changeServices(data){
        return Repository.put(`${resource}/services`,{data: data})
    },
    changeStatus(data){
        return Repository.put(`${resource}/status`,{data: data})
    },
    changeIp(id){
        return Repository.get(`${resource}/ip/${id}`)
    },
    changeIpMulti(ids){
        return Repository.post(`${resource}/ip`,ids)
    },

    getCountryList() {
        return Repository.get(`${resource}/country-code`)
    }

    
}