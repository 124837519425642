import AuthRepository from './authRepository'
import UsersInfoRepository from './users/userInfoRepository'
import PaymentInfoRepository from './admin/paymentInfoRepository'
import UsersManagerRepository from './admin/usersManagerReposiroty'
import UserPaymentsRepository  from './users/paymentsRepository'
import ReportsManager from './admin/reportsRepository'
import ReportsUser from './users/reportsRepository'
import PlanRepository from './users/plansRepository'
import BillingsRepository from './users/billingsRepository'
import Notifcations from './notificationsRepository'
import PlansManager from './admin/plansRepository'
import DashboardManager from './admin/DashboardRepository'
import SettingsManager from './admin/settingsRepository'
import Supports from './supportResposiroty'
import NPortManager from './users/nportsRepository'
import SNPortManager from './users/snportsRepository'
import NPlansManager from './users/nplansRepository'
import MNPortManager from './member/nportsRepository'
import ANPortManager from './admin/NportRepository'
import NPLanManager from './admin/nplansRepository'
import DomainManager from './users/domainRepository'

const repositories = {
    auths: AuthRepository,
    user: UsersInfoRepository,
    payment: PaymentInfoRepository,
    usermanagers: UsersManagerRepository,
    userPayment: UserPaymentsRepository,
    adminReport: ReportsManager,
    plans: PlanRepository,
    billings: BillingsRepository,
    notifications: Notifcations,
    userReport: ReportsUser,
    plansmanager: PlansManager,
    dashboardmanager: DashboardManager,
    settingsmanager: SettingsManager,
    suports: Supports,
    nport: NPortManager,
    snport: SNPortManager,
    nplans: NPlansManager,
    mnproxies: MNPortManager,
    anport: ANPortManager,
    anplan: NPLanManager,
    domain: DomainManager
}

const RepositoryFactory = {
    get: name => repositories[name]
}
export default RepositoryFactory