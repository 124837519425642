import Repository from '../Repository'

const resource = '/a/settings'
export default {
    fetchAlerts(){
        return Repository.get(`${resource}/alerts`) 
    },
    createAlert(){
        return Repository.post(`${resource}/alerts/`)
    },
    deleteAlert(id){
        return Repository.delete(`${resource}/alerts/${id}`)
    },
    saveAlert(id,params){
        return Repository.put(`${resource}/alerts/${id}`,params)
    }
    
}