import Repository from '../Repository'

const resource = '/u/snport'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    addPort(data){
        return Repository.post(`${resource}`,{data: data})
    },
    editPort(data){
        return Repository.put(`${resource}`,{data: data})
    },
    clonePort(id){
        return Repository.post(`${resource}/clone/`,id)
    },
    saveIp(data){
        return Repository.put(`${resource}/white-ip`,{data: data})
    },
    rewnewPort(data){
        return Repository.put(`${resource}/renew`,{data: data})
    },
    changeKeyport(data){
        return Repository.put(`${resource}/keyport`,{data: data})
    },
    changeIp(id){
        return Repository.get(`${resource}/ip/${id}`)
    },
    changeGeo(data){
        return Repository.put(`${resource}/geo`,{data: data})
    },
    changeRotate(data){
        return Repository.put(`${resource}/rotate`,{data: data})
    },

    changeServices(data){
        return Repository.put(`${resource}/services`,{data: data})
    },
    changeStatus(data){
        return Repository.put(`${resource}/status`,{data: data})
    },
    deletePort(data){
        return Repository.delete(`${resource}`,{data: data})
    }
}