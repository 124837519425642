import router from '@/router'
import axios from 'axios'
import Repository from '../../api/Repository'
import RepositoryFactory from '../../api/RepositoryFactory'
const auth = RepositoryFactory.get('auths')
const userInfo = RepositoryFactory.get('user')

export default {
    namespaced: true,  
    state: {
        token: localStorage.getItem('token') || '',
        user : null,
        settings: null,
        ports: null,
        plans: null
    },
    getters: {
        isLoggedIn: state => !!state.token,
        userInfo: state => state.user,
        settings: state => state.settings,
        ports: state => state.ports,
        plans: state => state.plans
    },

    actions: {
        login({commit,dispatch},param){
            return new Promise((resolve,reject)=>{
                auth.login(param).then(async (result)=>{
                    if(result.data){
                        localStorage.setItem('token',result.data.token)
                        Repository.defaults.headers.common['Authorization'] = result.data.token
                        const token = result.data.token
                        //console.log(result.data)
                        commit('auth_success',{token: token})
                        commit('update_info',{user: result.data.userInfo})
                        axios.get('https://lumtest.com/myip.json').then(rs=>{
                            localStorage.setItem('country', rs.data.country)
                        })
                        resolve(result.data)
                    }else{
                        reject(result)
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        memberlogin({commit,dispatch},param){
            return new Promise((resolve,reject)=>{
                auth.memberLogin(param).then(async (result)=>{
                    if(result.data){
                        localStorage.setItem('token',result.data.token)
                        Repository.defaults.headers.common['Authorization'] = result.data.token
                        const token = result.data.token
                        commit('auth_success',{token: token})
                        commit('update_info',{user: result.data.userInfo})
                        resolve(result.data)
                    }else{
                        reject(result)
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        register({commit},param){
            return new Promise((resolve,reject)=>{
                auth.register(param).then(async (result)=>{
                    if(result.data.success)
                        resolve({
                            success:true,
                            msg: 'Registion success'
                        })
                    else
                        resolve({
                            success:false,
                            msg: result.data.errMsg
                        })
                }).catch(e => {
                    reject(e)
                })
            })
        },
        get_uinfo({dispatch,commit}){
            return new Promise((resolve, reject) => {
                userInfo.fetch().then(result => {
                    commit('update_info',{user: result.data.data})
                    commit('update_settings',{settings: result.data.settings})
                    commit('update_ports',{ports: result.data.ports})
                    commit('update_plans',{plans: result.data.plans})
                    if(!localStorage.getItem("region")){
                        axios.get('https://lumtest.com/myip.json').then(rs=>{
                            if(rs.data.country)
                                localStorage.setItem('country', rs.data.country)
                        })
                    }
                    resolve(result)
                }).catch(e => {
                    reject(e)
                })
            })
        },
        logout({commit}){
            return new Promise((resolve, reject) => {
                commit('logout')
                localStorage.removeItem('token')
                delete Repository.defaults.headers.common['Authorization']
                router.push({ name: 'login' })
              //location.reload()
                resolve()
            })
        },
    },
    mutations: {
        auth_success(state, payload){
            state.token = payload.token
        },  
        update_info(state,payload){
            state.user = payload.user
        },
        update_settings(state,payload){
            state.settings = payload.settings
        },
        update_ports(state,payload){
            state.ports = payload.ports
        },
        update_plans(state,payload){
            state.plans = payload.plans
        },
        logout(state){
            state.token = ''
            state.user = null,
            state.settings = null
        }
    }
}