import Repository from './Repository'

const resource = '/helpdesk'
export default {
  fetch(){
    return Repository.get(`${resource}/ticket`) 
  },
  fetchById(id){
    return Repository.get(`${resource}/aticket/${id}`) 
  },
  createTicket(params){
    return Repository.post(`${resource}/ticket`,params)
  },
  getTicketContent(id){
    return Repository.get(`${resource}/ticket/${id}`)
  },
  addMessage(id,mess){
    return Repository.post(`${resource}/ticket/${id}`,mess)
  },
  closeTicket(id){
    return Repository.put(`${resource}/ticket/close/${id}`)
  }
}